.values, .boundaries{
	padding-top: 5em;
	p{
		color: $lime-green;
		font-family: $font-PTSans;
	    font-size: 1.7em;
	    max-width: 30em;
	    margin: 2em auto 1.84em auto;
	    line-height: 1.51em;        
	}
}

.values{
	input{
		width: 20em;
    	font-size: 1.4em;
    	margin: 0 30% 2.5em 30%;
	}
}
.boundaries{
    .act-btn-blue{
        margin: 1em .5em 0 .5em;
    }
}

.type-dont-look{
	width: 5em;
	height: 5em;
	margin: 13.5em auto 0;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0;
}

.type-dont-look.type{
	background-image: url(/images/type.svg);
}
.type-dont-look.dont-look{
	background-image: url(/images/dontlook.svg);
}