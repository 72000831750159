/* normalize */
@import "normalize.min";
/* vars */
$font-PTSans: 'PT Sans', sans-serif;
$font-Lato: 'Lato', sans-serif;
$font-Audiowide: 'Audiowide', sans-serif;
$lime-green: rgb(33,217,33);
$pink: rgb(255,153,255);
$light-blue: rgb(153,204,255);
$maroon: rgb(102,0,51);
$blue: rgb(0,51,102);
$green: darken($lime-green,22%);
$light-green: lighten(desaturate($lime-green, 35%), 45%);

/* mixins */
@mixin user-select-none() {
	-moz-user-select: none; 
    -khtml-user-select: none; 
    -webkit-user-select: none; 
    -o-user-select: none;
    user-select: none;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-bottom-radius($radius) {
	-moz-border-radius-bottomright: $radius;
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-moz-border-radius-bottomleft: $radius;
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

@mixin border-top-radius($radius) {
	-moz-border-radius-topright: $radius;
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	-moz-border-radius-topleft: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
}

@mixin bg-image-svg($name) {
	/* for browsers that DON'T support svg */
	background: url(#{$name}.png) no-repeat;
	/* for browsers that DO support svg */
	background: url(/images/nada.png), url(#{$name}.svg) no-repeat;
}

@mixin box-shadow($opacity) {
	-webkit-box-shadow: 0px 0px 1.25em 0.125em rgba(0,0,0,$opacity);
	-moz-box-shadow: 0px 0px 1.25em 0.125em rgba(0,0,0,$opacity);
	box-shadow: 0px 0px 1.25em 0.125em rgba(0,0,0,$opacity);
}

/* main scss */
body{
	background-color: #000;
	@include user-select-none();
	overflow: hidden;
}
input[disabled]{
	opacity: .4;
	cursor: not-allowed;
}

@-ms-viewport{
  width: device-width;
}

#main-wrapper{
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
}

.bg{
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
}

.bg[data-type="home"]{
	background-image: url(/images/stars_bg.jpg);
}

.bg[data-type="s1"]{
	background-image: url(/images/s1_bg.jpg);
}

.bg[data-type="s1-grid"]{
	background-image: url(/images/s1_bg_grid.jpg);
}

.bg[data-type="s2"]{
	background-image: url(/images/s2_bg.jpg);
}

.bg[data-type="s2-grid"]{
	background-image: url(/images/s2_bg_grid.jpg);
}

.bg[data-type="s2-l1"]{
	background-image: url(/images/s2_bg_landscape1.jpg);
}

.bg[data-type="s2-l2"]{
	background-image: url(/images/s2_bg_talkover.jpg);
}

.bg[data-type="s2-l3"]{
	background-image: url(/images/s2_bg_gesture.jpg);
}

#stage{
	text-align: center;
	padding-top: 78px;
}

#display{
	pointer-events: none;
}

#ui-wrapper{
	pointer-events: none;
}

#ui{
	text-align: center;
	pointer-events: none;
	.top{
		width: 100%;
		height: 100px;
		pointer-events: all;
	}
	.center{
		width: 1020px;
    	height: 446px;
    	margin: 40px 130px 20px;
    	position: absolute;
    	z-index: 900;
    	* {
    		pointer-events: all;
    	}
	}
	.bottom{
		width: 100%;
    	height: 150px;
    	margin: 510px 0;
    	position: absolute;
    	pointer-events: all;
	}
	.right, .left{
		width: 130px;
    	height: 510px;
    	pointer-events: all;
	}
	.right{
		float: right;
	}
	.left{
		float: left;
	}
}

.holder{
    width:1190px;
    height:568px;
    margin: 0 auto;
}

.holder2{
    margin-top: -568px;
}
/* this is what stage-clipped should be, but after Chrome update 55.0.2873.0 for Windows, Linux and  55.0.2873.4 for Mac this css is broken */
/* #video, div#stage-clipped{
	-webkit-clip-path:url(#grid-clip);
    clip-path:url(#grid-clip);
}

canvas#stage-clipped{
	-webkit-clip-path:url(#canvas-clip);
    clip-path:url(#canvas-clip);
} */

/* changed css for Chrome update 55.0.2873.0 for Windows, Linux and  55.0.2873.4 for Mac
** canvas#stage-clipped on chrome windows gets set to {-webkit-clip-path: none; clip-path: none} via js. see /js/session4/a_strategies.js
** fuck you google!
*/
#video{
    clip-path:url(#grid-clip);
}

#stage-clipped{
    clip-path:url(#canvas-clip);
}

.offstage {
	display: none;
	height: 0;
	width: 0;
	margin-left: -4000px;
}

.vert-align{
  	top: 50%;
  	-webkit-transform: translateY(-50%);
  	-ms-transform: translateY(-50%);
  	transform: translateY(-50%);
  	position: absolute;
  	overflow: hidden;
}

.top-align{
	top: 0;
  	transform: none;
}

.height-me{
	width: 100%;
	height: auto;
}

.scale-me{
	top: 0;
	left: 0;
	width: 1280px;
	height: 720px;
	position: absolute;
}

.vid-hotspot{
    width: 1020px;
    height: 446px;
    margin: 0 auto;
    position: absolute;
    cursor: pointer;
}

.box-shadow{
	@include box-shadow(0.8);
}

#cadet-head, #captain-head{
	width: 160px;
	height: 145px;
	background-image: url(/images/heads.png);
    background-repeat: no-repeat;
    background-size: 400%;
    background-position: 200px 200px;
    position: absolute;
    
}
#cadet-head{
	left: 196px;
    top: 23px;
}
#captain-head{
	top: 22px;
    left: 922px;
}
#cadet-head[data-type="cadet-1"]{
	background-position: 0px 0px;
}
#cadet-head[data-type="cadet-2"]{
	background-position: -160px 0px;
}
#captain-head[data-type="captain-1"]{
	background-position: -319px 0px;
	
}
#captain-head[data-type="captain-2"]{
	background-position: -479.4px 0px;
}
.avatar-loader{
	display: none;
}
.avatar-loader.svg{
	background-image: url(/images/cadet2.svg), url(/images/cadet1.svg), url(/images/captain2.svg), url(/images/captain1.svg);
}
.avatar-loader.png{
	background-image: url(/images/heads.png);
}
.star{
	height: 2.0625em;
	width: 2.0625em;
	background: url(/images/star.svg) no-repeat;
	background-size: contain;
}
.stripe{
	width:12em;
	opacity:0.2;
}
.stripe-cadet{
	margin-left:-11.65em;
	background: -moz-linear-gradient(left, rgba($blue,0) 0%, rgba($blue,0.5) 50%, rgba(darken($light-blue,20%),0.8) 90%, rgba($light-blue,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba($blue,0) 0%, rgba($blue,0.5) 50%, rgba(darken($light-blue,20%),0.8) 90%, rgba($light-blue,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba($blue,0) 0%, rgba($blue,0.5) 50%, rgba(darken($light-blue,20%),0.8) 90%, rgba($light-blue,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.stripe-captain{
	background: $pink;
	margin-left:99.6%;
	background: -moz-linear-gradient(left,  rgba($pink,1) 0%, rgba(darken($pink,40%),0.8) 10%, rgba($maroon,0.5) 50%, rgba($maroon,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba($pink,1) 0%, rgba(darken($pink,40%),0.8) 10%, rgba($maroon,0.5) 50%, rgba($maroon,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba($pink,1) 0%, rgba(darken($pink,40%),0.8) 10%, rgba($maroon,0.5) 50%, rgba($maroon,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.timer{
	color: #FFF;
    font-family: "Audiowide", sans-serif;
    font-size: 4.5em;
    margin: -.1em auto;
    span{
    	width: 0.9em;
    	margin: 0;
    	display: inline-block;
    }
    .colon{
    	width: 0.2em;
		margin-right: 0.05em;
    }
}

.home{ 
	.split{
		font-family: $font-Audiowide;
		color: #FFF;
		font-size: 3em;
		margin-top: 1.3em;
	}
	.act-alert{
		width: 48em;
		margin-left:-26em;
		background-color: rgba($light-blue,0.88);
		p{line-height: 2.2em;}
		.btns{
			a{
				font-family: $font-PTSans;
				font-weight: 700;
				font-size: 1.3em;
				text-decoration: none;
				color: $blue;
				background-color: $light-blue;
				border: 0.1em solid $blue;
				margin: 0.5em;
				padding: 0.2em 0.4em;
				display: inline-block;
			}
			a.challenge{
			background-color: $lime-green;
			}
		}
	}
}

/* other sheets */
@import "svg";
@import "buttons";
@import "slider";
@import "components";
@import "login_profile";
@import "a_parent_quiz";
@import "a_avatars";
@import "a_values_boundaries";
@import "a_talkover_gesture_myths_behavior";
@import "a_brainstorm_bodytalk";
@import "a_strategies";
@import "a_glt1";
@import "a_whizquiz";
@import "a_stresscomm";
@import "a_anatomy";
@import "a_scenarios";