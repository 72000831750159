$uiColor1: $lime-green;
$uiTxtColor: #FFF;
$uiFont: $font-PTSans;

.range {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5em 4em;
  left: 0;
  position: absolute;
s
  .slider-wrap {
    margin: 0;
    padding: 0;
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 7.5em;
    height: 1.5em;
    margin: auto;
    border-radius: 1.5em;
    overflow: hidden;
    background-color: #000;
    outline: none;
    font-size: 1.4em;
    cursor: pointer;
  }

  .slider:focus {
    outline: 0;
  }

  .slider[max="1"] {
    width: 2.5em;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    border: 0.2em solid $uiColor1;
    background: $uiTxtColor;
    box-shadow: -8.4em 0 0 7.5em $uiColor1;
  }

  .slider::-moz-range-thumb {
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    border: 0.2em solid $uiColor1;
    background: $uiTxtColor;
    box-shadow: -8.4em 0 0 7.5em $uiColor1;
    box-sizing: border-box;
  }

  .slider::-moz-range-track {
    visibility: hidden;
  }

  .slider::-ms-thumb {
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    border: 0.2em solid $uiColor1;
    background: $uiTxtColor;
    box-sizing: border-box;
  }

  .slider::-ms-fill-lower {
    background: $uiColor1;
  }

  .slider::-ms-ticks-after,
  .slider::-ms-ticks-before,
  .slider::-ms-tooltip {
    display: none;
  }

  .text {
    color: $uiTxtColor;
    font-family: $uiFont;
    width: 16em;
    margin: 0.5em 0;
    display: inline-flex;
    justify-content: center;

    .lang, .value {
      margin: 0 0.4em;
    }

    .value {
      font-style: italic;
    }
  }
}

html[lang="en"] { 
  .range .text {
    .lang:before {
      content: 'Select Language: ';
    }

    .value:before {
      content: 'English';
    }
  }
}

html[lang="es"] {
  .range .text {
    .lang:before {
      content: 'Seleccione el Idioma: ';
    }

    .value:before {
      content: 'Español';
    }
  }
}