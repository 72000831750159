#reReadLink {
	cursor: pointer;
	display: block;
    margin-top: 16px;
    text-decoration: underline;
}
#main-btn[data-type="show-strategies"]:before{
	content:'SEE STRATEGY';
	font-size: .5em;
}
.white-words1 {
	color: #fff;
}
.second-white-words1 {
	color: #fff;	
}
.second-white-words2 {
	color: #fff;	
}
.third-white-words1 {
	color: #fff;	
}

.boundaries-txt.glt {
	font-size:1.54em;
	line-height: 1.34em;
	max-width: 38em;
	text-align: left;	
}
