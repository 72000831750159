html[lang="en"] {
    .parent-quiz-svg .pregunta {
        display: none;
    }
}

html[lang="es"] {
    .parent-quiz-svg .question {
        display: none;
    }
}

.parent-quiz-svg {
    #q1,
    #q2,
    #q3 {
        cursor: pointer;
        @include user-select-none();

        .cls-2,
        .cls-3 {
            fill: #9cf;
        }

        .cls-2:hover,
        .cls-3:hover {
            fill: #FFF;
        }

        .cls-4 {
            fill: #f9f;
            opacity: 0.5
        }
    }

    #q1 {
        .cls-1 {
            fill: #3d1447;
        }
    }

    #q2 {
        .cls-1 {
            fill: #291f52;
        }
    }

    #q3 {
        .cls-1 {
            fill: #14295c;
        }
    }

    #q1:hover,
    #q2:hover,
    #q3:hover {

        .cls-2,
        .cls-3 {
            fill: #FFF;
        }
    }

    #q1[data-type="correct"]:hover,
    #q2[data-type="correct"]:hover,
    #q3[data-type="correct"]:hover,
    #q1[data-type="incorrect"]:hover,
    #q2[data-type="incorrect"]:hover,
    #q3[data-type="incorrect"]:hover,
    #q1[data-type="correcto"]:hover,
    #q2[data-type="correcto"]:hover,
    #q3[data-type="correcto"]:hover,
    #q1[data-type="incorrecto"]:hover,
    #q2[data-type="incorrecto"]:hover,
    #q3[data-type="incorrecto"]:hover {

        .cls-2,
        .cls-3 {
            fill: #9cf;
        }
    }

    #q1[data-type="correct"],
    #q2[data-type="correct"],
    #q3[data-type="correct"],
    #q1[data-type="incorrect"],
    #q2[data-type="incorrect"],
    #q3[data-type="incorrect"],
    #q1[data-type="correcto"],
    #q2[data-type="correcto"],
    #q3[data-type="correcto"],
    #q1[data-type="incorrecto"],
    #q2[data-type="incorrecto"],
    #q3[data-type="incorrecto"] {
        opacity: 0.3;
        cursor: default;

        .cls-3 {
            opacity: 0.8;
        }
    }

    #q1[data-type="correct"],
    #q2[data-type="correct"],
    #q3[data-type="correct"],
    #q1[data-type="correcto"],
    #q2[data-type="correcto"],
    #q3[data-type="correcto"] {
        .cls-1 {
            fill: $lime-green;
        }
    }

    #q1[data-type="incorrect"],
    #q2[data-type="incorrect"],
    #q3[data-type="incorrect"],
    #q1[data-type="incorrecto"],
    #q2[data-type="incorrecto"],
    #q3[data-type="incorrecto"] {
        .cls-1 {
            fill: #D921A7;
        }
    }
}
