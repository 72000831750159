$skin-color: darken(#c6a28d, 6%);
$skin--outline-color: darken(#af8c72, 7%);
$line-color: $lime-green;
$line-width: 2px;
$fill-color: red;
$pubes-color: #a0836e;

.anatomy {
	.bodyparts {
		opacity: 0;
	}

	.penis,
	.pene,
	.urethra,
	.uretra,
	.testicle,
	.testículos,
	.scrotum,
	.escroto,
	.anus,
	.ano,
	.fallopian-tubes,
	.tubos-de-falopio,
	.ovaries,
	.ovarios,
	.vagina,
	.labia,
	.labios-vaginales,
	.clitoris,
	.clítoris {
		cursor: pointer;
		position: absolute;
	}

	.act-btn.male.penis,
	.act-btn.male.pene {
		top: 6em;
		left: 14.6em;
	}

	.act-btn.male.urethra,
	.act-btn.male.uretra {
		top: 2.9em;
		left: 16.4em;
	}

	.act-btn.male.testicle,
	.act-btn.male.testículos {
		top: 13.4em;
		left: 30em;
	}

	.act-btn.male.scrotum,
	.act-btn.male.escroto {
		top: 13em;
		left: 11.4em;
	}

	/* .act-btn.male.anus {
		top: 7.8em;
		left: 31.82em;
	} */

	.act-btn.female-inside.fallopian-tubes,
	.act-btn.female-inside.tubos-de-falopio {
		top: 0.7em;
		left: 13em;
	}

	.act-btn.female-inside.ovaries,
	.act-btn.female-inside.ovarios {
		top: 3.2em;
		left: 30.8em;
	}

	.act-btn.female-inside.vagina {
		top: 13.2em;
		left: 30em;
	}

	.act-btn.female-outside.clitoris,
	.act-btn.female-outside.clítoris {
		top: 1em;
		left: 23.5em;
	}

	.act-btn.female-outside.urethra,
	.act-btn.female-outside.uretra {
		top: 4.2em;
		left: 15.9em;
	}

	.act-btn.female-outside.anus,
	.act-btn.female-outside.ano {
		top: 13.5em;
		left: 30.6em;
	}

	.act-btn.female-outside.labia {
		top: 10.2em;
		left: 14.4em;
	}

	.act-btn.female-outside.labios-vaginales {
		top: 11.3em;
		left: 13.4em;
	}

	.act-btn.female-outside.vagina {
		top: 8.52em;
		left: 28em;
	}

	#male {
		.cls-1 {
			fill: $pubes-color;
		}

		.cls-2 {
			fill: $skin-color;
		}

		.cls-3 {
			fill: $skin--outline-color;
		}

		.cls-4 {
			fill: $fill-color;
			opacity: 0.2;
			isolation: isolate;
		}

		.cls-5 {
			fill: #fff;
		}

		.cls-6,
		.cls-7,
		.cls-8,
		.cls-9 {
			fill: none;
		}

		.cls-6,
		.cls-7 {
			stroke: #fff;
		}

		.cls-6 {
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 1.3px;
		}

		.cls-7,
		.cls-8,
		.cls-9 {
			stroke-miterlimit: 10;
		}

		.cls-7 {
			stroke-width: 0.95px;
		}

		.cls-8 {
			stroke: rgba(0, 0, 0, 0);
		}

		.cls-9 {
			stroke: $line-color;
			stroke-width: $line-width;
		}

		.cls-10 {
			fill: $line-color;
		}
	}

	#female-inside {
		.cls-1 {
			fill: $skin-color;
		}

		.cls-2 {
			fill: $pubes-color;
		}

		.cls-3 {
			fill: $skin--outline-color;
		}

		.cls-4 {
			fill: $fill-color;
			opacity: 0.2;
			isolation: isolate;
		}

		.cls-5 {
			fill: #fff;
		}

		.cls-6,
		.cls-7 {
			fill: none;
			stroke: $line-color;
			stroke-miterlimit: 10;
		}

		.cls-7 {
			stroke-width: $line-width;
		}

		.cls-8 {
			fill: $line-color;
		}
	}

	#female-outside {
		.cls-1 {
			fill: $skin-color;
		}

		.cls-2 {
			opacity: 0.6;
		}

		.cls-3 {
			fill: $pubes-color;
		}

		.cls-4 {
			fill: $skin--outline-color;
		}

		.cls-5 {
			fill: $fill-color;
			opacity: 0.2;
			isolation: isolate;
		}

		.cls-6 {
			fill: #fff;
		}

		.cls-7,
		.cls-8 {
			fill: none;
			stroke: $line-color;
			stroke-miterlimit: 10;
		}

		.cls-8 {
			stroke-width: $line-width;
		}

		.cls-9 {
			fill: $line-color;
		}
	}


}
