.bodytalk {
	.points {
		font-family: $font-Audiowide;
		font-size: 2em;
		width: 100%;
		line-height: 0.96em;
		margin: 6.7em auto;

		.pts:before {
			font-size: .62em;
			font-family: $font-Lato;
			color: $lime-green;
		}
	}

	.cdt {
		color: $light-blue;
	}

	.cptn {
		color: $pink;
	}

	.countdown {
		color: #e0a20d;
		font-size: 6em;
		font-family: $font-PTSans;
		font-weight: 700;
		margin-top: 1em;
		display: none;
	}
}

html[lang="en"] {
	.brainstorm .points span:before {
		content: 'words';
	}
	.bodytalk .points .pts:before {
		content: 'points';
	}
}

html[lang="es"] {
	.brainstorm .points span:before {
		content: 'palabras';
	}
	.bodytalk .points .pts:before {
		content: 'puntos';
	}
}

.brainstorm {
	input {
		font-family: $font-Lato;
		font-size: 1.5em;
		width: 9em;
		margin: -.15em auto 0;
		background-color: rgba(#FFF, .5);
	}

	.points {
		color: $light-blue;
		font-family: $font-Audiowide;
		font-size: 3em;
		margin: 4.2em auto;
		width: 1.8em;
		line-height: 0.8em;
		display: flex;
		flex-direction: column;

		span:before {
			font-size: .5em;
			font-family: $font-Lato;
			color: $lime-green;
		}
	}

	.right .act-btn {
		margin-top: 11em;
	}

	.hint {
		width: 162px;
		height: 130px;
		background-color: rgba($maroon, .75);
		border: .12em solid $pink;
		position: relative;
		margin-top: -22.5em;
		float: right;
		margin-right: 8.2em;
		/* display: inline-block; */
		background-repeat: no-repeat;
		background-image: url(/images/brainstorm/emoji_sprite.png);
		overflow: hidden;
	}

	.type-display {
		color: #FFF;
		font-family: $font-Audiowide;
		font-size: 6em;
		text-align: center;
		text-transform: uppercase;
		background-color: rgba($light-blue, 0.25);
		@include border-radius(4em);
		margin: 0.5em 2%;
		width: 96%;
		opacity: 0.75;
		position: absolute;
		overflow: hidden;
	}

	/* .emojis_1 {background-position: -1px -1px}
	.emojis_10 {background-position: -165px -1px}
	.emojis_2 {background-position: -329px -1px}
	.emojis_3 {background-position: -493px -1px}
	.emojis_4 {background-position: -657px -1px}
	.emojis_5 {background-position: -821px -1px}
	.emojis_6 {background-position: -985px -1px}
	.emojis_7 {background-position: -1149px -1px}
	.emojis_8 {background-position: -1313px -1px}
	.emojis_9 {background-position: -1477px -1px} */
	.emojis_1 {
		background-position: -1px -1px
	}

	.emojis_2 {
		background-position: -165px -1px
	}

	.emojis_3 {
		background-position: -329px -1px
	}

	.emojis_4 {
		background-position: -493px -1px
	}

	.emojis_5 {
		background-position: -657px -1px
	}

	.emojis_6 {
		background-position: -821px -1px
	}

	.emojis_7 {
		background-position: -985px -1px
	}
}

.zombie {
	position: fixed;
	height: 100%;
	width: 100%;
	background: #FFF no-repeat url(/images/zombie.jpg);
	background-size: 100% 100%;
	display: none;
}

.emoji-table {
	width: 40em;
	height: 27em;
	margin: -.3em -20.8em;
	padding: .8em;
	left: 50%;
	color: $blue;
	font-family: $font-Lato;
	cursor: pointer;
	background-color: rgba($light-blue, .92);
	position: absolute;
	@include border-radius(1em);
	overflow: hidden;

	.word {
		font-family: $font-PTSans;
		font-weight: 700;
		font-size: 1.2em;
		padding: .1em;
		border: thin solid $blue;
		background-color: $light-blue;
		width: 9em;
		float: left;
		margin: .3em .6em;
	}

	.on {
		color: $light-blue;
		background-color: $blue;
	}

	p {
		width: 38.75em;
		margin-top: .6em;
		padding: .8em;
		text-align: left;
		float: left;
	}

	/* .act-btn {
		margin: 15em 0 0 -95%;
	} */
}
