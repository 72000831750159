#t-holder, #c-holder{
	width: 100%;
	height: 100%;
	margin: 0 22%;
}

#t-holder *, #c-holder *{
	  margin-top: 1.4em;
	  background-repeat: no-repeat;
	  background-size: contain;
	  float: left;
	  cursor: pointer;
}

#t-holder *:hover, #c-holder *:hover, #t-holder *:active, #c-holder *:active, .choosen-avtr{
	  -webkit-box-shadow: 1px 1px 8px 6px rgba($lime-green,0.3) ;
	   box-shadow: 1px 1px 8px 6px rgba($lime-green,0.3) ;
}

#cadet-1{
	width: 195px;
    height: 510px;
    margin-right: 303px;
    background-image: url(/images/cadet1.svg);
}
#cadet-2{
	width: 142px;
    height: 525px;
    background-image: url(/images/cadet2.svg);
}
#captain-1{
	width: 180px;
    height: 510px;
    margin-right: 276px;
  	background-image: url(/images/captain1.svg);
}
#captain-2{
	width: 235px;
    height: 515px;
  	background-image: url(/images/captain2.svg);	
}

