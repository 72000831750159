.act-load-anim{
	left: 50%;
	top: 50%;
	margin: -1.25em 0 0 -4.5625em;
	position: absolute;
	z-index: 500;
	pointer-events: none;
}

.act-note-text
{
	font-family: 'PT Sans', sans-serif;
	font-weight: 700;
	color: $maroon;
	font-size: 1.15em;
	text-align: center;
	margin: 1em 1.25em; 
}

.act-note-div 
{
	top: 0px;
	left: 0px;
	width: 25em;
	height: auto;
	position: absolute;
	overflow: visible;
}

.act-note 
{
	background-color: #FFF;
	border: .25em solid #FFF;
	-moz-border-radius: 1em;
	border-radius: 1em;
	position: absolute;
	overflow: visible;
	@include box-shadow(0.8);
}

.act-note:after, .act-note:before 
{
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
}

.act-note:after { border-color: rgba(0, 0, 0, 0);}
.act-note:before { border-color: rgba(0, 0, 0, 0);}

.act-note-bottom:after, .act-note-bottom:before 
{
	top: 100%;
}

.act-note-bottom:after 
{
	border-top-color: #FFF;
	left: 50%;
	margin-left: -0.875em;
	border-width: 0.875em;
}

.act-note-bottom:before 
{
	border-top-color: #FFF;
	left: 50%;
	margin-left: -1.25em;
	border-width: 1.25em;
}

.act-note-top:after, .act-note-top:before 
{
	bottom: 100%;
}

.act-note-top:after 
{
	border-bottom-color: #FFF;
	left: 50%;
	margin-left: -0.875em;
	border-width: 0.875em;
}

.act-note-top:before 
{
	border-bottom-color: #FFF;
	left: 50%;
	margin-left: -1.25em;
	border-width: 1.25em;
}

.act-note-tb-1:after, .act-note-tb-1:before {left: 25%;}
.act-note-tb-2:after, .act-note-tb-2:before {left: 50%;}
.act-note-tb-3:after, .act-note-tb-3:before {left: 75%;}

.act-note-right:after, .act-note-right:before 
{
	left: 100%;
}

.act-note-right:after
{
	border-left-color: #FFF;
	top: 50%;
	margin-top: -0.75em;
	border-width: 0.75em;
}

.act-note-right:before 
{
	border-left-color: #FFF;
	top: 50%;
	margin-top: -1.125em;
	border-width: 1.125em;
}

.act-note-left:after, .act-note-left:before 
{
	right: 100%;
}

.act-note-left:after
{
	border-right-color: #FFF;
	top: 50%;
	margin-top: -0.75em;
	border-width: 0.75em;
}

.act-note-left:before 
{
	border-right-color: #FFF;
	top: 50%;
	margin-top: -1.125em;
	border-width: 1.125em;
}

.act-note-lr-1:after, .act-note-lr-1:before {top: 25%;}
.act-note-lr-2:after, .act-note-lr-2:before {top: 50%;}
.act-note-lr-3:after, .act-note-lr-3:before {top: 75%;}

.act-alert{
	top: 50%;
	left: 50%;
	width: 38em;
	height: auto; 
	padding: 2em 2em;
	margin-left: -20em;
	background-color: rgba($light-blue,0.82);
	-moz-border-radius: 1em;
	-webkit-border-radius: 1em;
	border-radius: 1em;
	position: absolute;
	text-align: center;
	font-family: 'PT Sans', sans-serif;
	z-index: 1020;
	img{
		margin: 1em auto 0em auto;
	}
	.timer{
		margin: .1em auto -.3em auto;
	}
}
.act-alert-cadet{
	background: -moz-linear-gradient(top,  rgba($light-blue,0.8) 0%, rgba($light-blue,0.8) 65%, rgba($blue,0.9) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba($light-blue,0.8) 0%,rgba($light-blue,0.8) 65%,rgba($blue,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba($light-blue,0.8) 0%,rgba($light-blue,0.8) 65%,rgba($blue,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$light-blue, endColorstr=$blue,GradientType=0 ); /* IE6-9 */
}
.act-alert-captain{
	background: -moz-linear-gradient(top,  rgba($light-blue,0.8) 0%, rgba($light-blue,0.8) 65%, rgba($maroon,0.9) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba($light-blue,0.8) 0%,rgba($light-blue,0.8) 65%,rgba($maroon,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba($light-blue,0.8) 0%,rgba($light-blue,0.8) 65%,rgba($maroon,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$light-blue, endColorstr=$maroon,GradientType=0 ); /* IE6-9 */
}

.act-alert-txt{
	font-size: 1.5em;
	margin: 0 auto;
	ul, ol{
		text-align: left;
	}
	li{
		margin: .65em 0;
	}
}

.act-alert-txt .correct,
.act-alert-txt .correcto {
	color: $blue;
	font-weight: 700;
}

.act-alert-txt .incorrect,
.act-alert-txt .incorrecto {
	color: $maroon;
	font-weight: 700;
}

.act-alert button{
	margin: 1em .5em 0 .5em;
}

.act-question-box{
	text-align: left;
	button{
		margin: 0.6em 0.6em;
	}
	.act-alert-txt{
		font-size: 1.5em;
		margin: 0.8em 0.8em;
	}
}
.act-question-box-txt{
	font-size: 1.5em;
    margin: -2.4em 0 0.6em 3.85em;
}

.act-volume{
	width: 5.125em; /* 4em; */
	height: 15.875em;
	margin-top: 8.3em;
	background-repeat: no-repeat;
	background-size: contain;
	.groove{
		width: 0.375em;
		height: 12.5em;
    	position: absolute;
		background-color: #000;
		overflow: hidden;
		@include border-radius(0.198em);
	}
	.level{
		background-color: #d64133;
		width: 100%;
		height: 100%;
		margin-top: 12.5em;
	}
	.handle{
		width: 3em;
		height: 3.5em;
		margin-top: 11.5em;
		position: absolute;
		/* border: thin solid red; */
	}
	.bar{
		width: 2em;
		height: 0.4375em;
		margin: (2-0.4375)+em auto 0;
		background-color: #FFF;
		@include border-radius(0.229em);
	}
}

.act-volume[data-type="cadet"]{
	margin-left: 1.5em;
	.groove{
		margin: .9em 3.54em;
	}
	.handle{
		margin-left: 2.3em;
	}
}

.act-volume[data-type="captain"]{
	margin-left: 1.6em;
	.groove{
		margin: .9em 1em;
	}
	.handle{
		margin-left: -0.3em;          
	}
}

html[lang="en"] {
	.act-volume[data-type="cadet"] {
		background-image: url(/images/volume_cadet_w_label.svg);
	}
	.act-volume[data-type="captain"] {
		background-image: url(/images/volume_captain_w_label.svg);
	}
}

html[lang="es"] {
	.act-volume[data-type="cadet"] {
		background-image: url(/images/volume_cadet_w_label_es.svg);
	}

	.act-volume[data-type="captain"] {
		background-image: url(/images/volume_captain_w_label_es.svg);
	}
}


