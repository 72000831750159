.whiz-quiz-svg {
    #q0,
    #q1,
    #q2,
    #q3,
    #q4,
    #q5,
    #q6,
    #q7,
    #q8,
    #q9,
    #q10,
    #q11,
    #q12,
    #q13,
    #q14 {

        cursor: pointer;
        @include user-select-none();

        .cls-2,
        .cls-3 {
            fill: #9cf;
        }

        .cls-2:hover,
        .cls-3:hover {
            fill: #FFF;
        }

        .cls-4 {
            fill: #f9f;
            opacity: 0.5
        }
    }

    #q0,
    #q5,
    #q10 {
        .cls-1 {
            fill: #14295c;
        }
    }

    #q1,
    #q6,
    #q11 {
        .cls-1 {
            fill: #3d1447;
        }
    }

    #q2,
    #q7,
    #q12 {
        .cls-1 {
            fill: #291f52;
        }
    }

    #q3,
    #q8,
    #q13 {
        .cls-1 {
            fill: #14295c;
        }
    }

    #q4,
    #q9,
    #q14 {
        .cls-1 {
            fill: #3d1447;
        }
    }

    #q0:hover,
    #q1:hover,
    #q2:hover,
    #q3:hover,
    #q4:hover,
    #q5:hover,
    #q6:hover,
    #q7:hover,
    #q8:hover,
    #q9:hover,
    #q10:hover,
    #q11:hover,
    #q12:hover,
    #q13:hover,
    #q14:hover {

        .cls-2,
        .cls-3 {
            fill: #FFF;
        }
    }

    #q0[data-type="correcto"]:hover,
    #q1[data-type="correcto"]:hover,
    #q2[data-type="correcto"]:hover,
    #q3[data-type="correcto"]:hover,
    #q4[data-type="correcto"]:hover,
    #q5[data-type="correcto"]:hover,
    #q6[data-type="correcto"]:hover,
    #q7[data-type="correcto"]:hover,
    #q8[data-type="correcto"]:hover,
    #q9[data-type="correcto"]:hover,
    #q10[data-type="correcto"]:hover,
    #q11[data-type="correcto"]:hover,
    #q12[data-type="correcto"]:hover,
    #q13[data-type="correcto"]:hover,
    #q14[data-type="correcto"]:hover,
    #q0[data-type="incorrecto"]:hover,
    #q1[data-type="incorrecto"]:hover,
    #q2[data-type="incorrecto"]:hover,
    #q3[data-type="incorrecto"]:hover,
    #q4[data-type="incorrecto"]:hover,
    #q5[data-type="incorrecto"]:hover,
    #q6[data-type="incorrecto"]:hover,
    #q7[data-type="incorrecto"]:hover,
    #q8[data-type="incorrecto"]:hover,
    #q9[data-type="incorrecto"]:hover,
    #q10[data-type="incorrecto"]:hover,
    #q11[data-type="incorrecto"]:hover,
    #q12[data-type="incorrecto"]:hover,
    #q13[data-type="incorrecto"]:hover,
    #q14[data-type="incorrecto"]:hover {

        .cls-2,
        .cls-3 {
            fill: #9cf;
        }
    }

    #q0[data-type="correcto"],
    #q1[data-type="correcto"],
    #q2[data-type="correcto"],
    #q3[data-type="correcto"],
    #q4[data-type="correcto"],
    #q5[data-type="correcto"],
    #q6[data-type="correcto"],
    #q7[data-type="correcto"],
    #q8[data-type="correcto"],
    #q9[data-type="correcto"],
    #q10[data-type="correcto"],
    #q11[data-type="correcto"],
    #q12[data-type="correcto"],
    #q13[data-type="correcto"],
    #q14[data-type="correcto"],
    #q0[data-type="incorrecto"],
    #q1[data-type="incorrecto"],
    #q2[data-type="incorrecto"],
    #q3[data-type="incorrecto"],
    #q4[data-type="incorrecto"],
    #q5[data-type="incorrecto"],
    #q6[data-type="incorrecto"],
    #q7[data-type="incorrecto"],
    #q8[data-type="incorrecto"],
    #q9[data-type="incorrecto"],
    #q10[data-type="incorrecto"],
    #q11[data-type="incorrecto"],
    #q12[data-type="incorrecto"],
    #q13[data-type="incorrecto"],
    #q14[data-type="incorrecto"] {
        opacity: 0.3;
        cursor: default;

        .cls-3 {
            opacity: 0.8;
        }
    }

    #q0[data-type="correcto"],
    #q1[data-type="correcto"],
    #q2[data-type="correcto"],
    #q3[data-type="correcto"],
    #q4[data-type="correcto"],
    #q5[data-type="correcto"],
    #q6[data-type="correcto"],
    #q7[data-type="correcto"],
    #q8[data-type="correcto"],
    #q9[data-type="correcto"],
    #q10[data-type="correcto"],
    #q11[data-type="correcto"],
    #q12[data-type="correcto"],
    #q13[data-type="correcto"],
    #q14[data-type="correcto"] {
        .cls-1 {
            fill: $lime-green;
        }
    }

    #q0[data-type="incorrecto"],
    #q1[data-type="incorrecto"],
    #q2[data-type="incorrecto"],
    #q3[data-type="incorrecto"],
    #q4[data-type="incorrecto"],
    #q5[data-type="incorrecto"],
    #q6[data-type="incorrecto"],
    #q7[data-type="incorrecto"],
    #q8[data-type="incorrecto"],
    #q9[data-type="incorrecto"],
    #q10[data-type="incorrecto"],
    #q11[data-type="incorrecto"],
    #q12[data-type="incorrecto"],
    #q13[data-type="incorrecto"],
    #q14[data-type="incorrecto"] {
        .cls-1 {
            fill: #D921A7;
        }
    }
}
