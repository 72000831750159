.strategies{
	.emo-volume{
		top: .4em;
		font-family: $font-Audiowide;
        font-size: 2.5em;
	    line-height: 0.75em;
	    position: absolute;
	    opacity:0.5;
	    width: 1.6em;
		.vol:before{
			content:'vol:';
			font-size: .5em;
			font-family: $font-Lato;
			color: $lime-green;
		}
	}
	.emo-volume.cdt{
		color: $light-blue;
		left: 1.3em;
	}
	.emo-volume.cptn{
		color: $pink;
		left: 29.1em;
	}
	.stars{
		top: 3.1em;
		width: 23.1em;
		height: 2em;
		opacity:0.5;
		position: absolute;
	}
	.smaller{
		font-size: .75em;
	}
	.stars.cdt{
		left: 9em;
		.star{
			float:left;
			margin-right: .5em;
		}
	}
	.stars.cptn{
		left: 48.5em;
		.star{
			float:right;
			margin-left: .5em;
		}
	}
	.star{
		margin-bottom: .5em;
	}
	.stars.move-up{
		margin-top: -0.85em;
	}
	.act-note-div.healthy, .act-note-div.unhealthy{
		top: 3em;
	}
	.act-note-div.healthy{
		left: 5.3em;
	}
	.act-note-div.unhealthy{
		left: 39.3em;
	}
}

html[lang="en"] {
	.strategies {
		.act-note-div.healthy p:after {
			content: ' healthy strategies captured.'
		}
		.act-note-div.unhealthy p:after {
			content: ' unhealthy strategies captured.'
		}
	}
}

html[lang="es"] {
	.strategies {
		.act-note-div.healthy p:after {
			content: ' estrategias saludables capturadas.'
		}

		.act-note-div.unhealthy {
			left: 35.8em;
				p:after {
					content: ' estrategias poco saludables capturadas.'
			}
		}
	}
}