html[lang="en"] {
	.gesture {
		padding-top: 8.22em;

		.words5 {
			color: #FFF;
			font-family: $font-Audiowide;
			font-size: 1.25em;
		}
	}

	.behavior .answer .emo:before {
		content: 'emotion(s):';
	}
}

html[lang="es"] {
	.gesture {
		padding-top: 8.22em;

		.words4 {
			color: #FFF;
			font-family: $font-Audiowide;
			font-size: 1.25em;
		}
	}

	.behavior .answer .emo:before {
		content: 'emociones:';
	}
}

.talkover,
.gesture,
.myths,
.behavior {
	p {
		color: $lime-green;
		font-family: $font-PTSans;
		font-size: 1.9em;
		max-width: 30em;
		margin: 0em auto 0;
		line-height: 1.4em;
	}

	.timer {
		color: #FFF;
		font-family: "Audiowide", sans-serif;
		font-size: 9em;
		margin: -0.1em auto;

		span {
			width: 1em;
			margin: 0;
			display: inline-block;
		}

		.colon {
			width: 0.2em;
			margin-right: .1em;
		}
	}
}

.talkover {
	padding-top: 8.22em;

	p {
		max-width: 29em;
	}

	.sametime {
		font-size: 2.5em;
		line-height: 1em;
	}

	.sametime.blink {
		color: #FFF;
	}
}

.talky-face {
	height: 5em;
	width: 5em;
	margin: 13.8em auto;
	background: url(/images/talkyFace.gif) no-repeat;
	background-size: contain;
	opacity: 0;
}

.myths {
	padding-top: 11em;
}

.behavior {
	p {
		font-size: 1.8em;
		line-height: 1.5em;
		text-align: left;
		max-width: 19em;
		position: absolute;
		margin-left: 18em;
		top: 10.3em;
	}

	.sam {
		width: 21.875em;
		height: 22.5em;
		position: absolute;
		margin-left: 8.8em;
		top: 12.5em;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.answers {
		font-family: $font-Audiowide;
		font-size: 2.5em;
		line-height: 0.9em;
		position: absolute;
		opacity: 0.5;
		width: 20em;

		.emo:before {
			font-size: .5em;
			font-family: $font-Lato;
			color: $lime-green;
		}

		.wrds {
			margin-top: -.1em;
			display: inline-block;
		}
	}

	.cdt {
		text-align: left;
		color: $light-blue;
		margin: .4em 3.6em;
	}

	.cptn {
		text-align: right;
		color: $pink;
		margin: .4em 8.5em;
	}
}

.behavior-word {
	color: $blue;
	font-family: $font-PTSans;
	font-weight: 700;
	font-size: 1.2em;
	padding: .1em;
	border: thin solid $blue;
	background-color: $light-blue;
	width: 9em;
	float: left;
	margin: .3em .6em;
	cursor: pointer;
}

.behavior-word-on {
	color: $light-blue;
	background-color: $blue;
}
