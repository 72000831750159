.stresscomm{

	.puzzle{
		height: 100%;
		width: 100%;
		background: url('/images/a_stresscomm/robotFull.png');
		background-position: 250px 20px;
		background-size: 230px auto;
	    background-repeat: no-repeat;
	}
	.puzzle2{
		height: 100%;
		width: 100%;
		background: url('/images/a_stresscomm/shipFull.png');
		background-position: 250px 64px;
		background-size: 240px auto;
	    background-repeat: no-repeat;
	}
	.jumbled{
		height: 530px;
	    max-width: 640px;    
	    position: relative;
	    left: 40%;
		background: url('/images/a_stresscomm/robotFullSil.png');
		background-position: 234px 20px;
		background-size: 230px auto;
	    background-repeat: no-repeat;
	}
	.jumbled2{
		height: 530px;
	    max-width: 640px;    
	    position: relative;
	    left: 40%;
		background: url('/images/a_stresscomm/shipFullSil.png');
		background-position: 234px 66px;
		background-size: 240px auto;
	    background-repeat: no-repeat;
	}

	.pieces {
		display: inline-block;
	}

	#p1 {
		height: 112px;
		width: 125px;
		background: url('/images/a_stresscomm/robot1.png');
		background-size: contain;
    	background-repeat: no-repeat;
		position: relative;
		left: 201px;
		top: -62px;    	   			   	    	
	}
	#p2{
		height: 134px;
		width: 76px;
		background: url('/images/a_stresscomm/robot2.png');
		background-size: contain;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 47px;
    	left: 98px;		
	}
	#p3{
		height: 191px;
		width: 87px;
		background: url('/images/a_stresscomm/robot3.png');
		background-size: contain;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 110px;
    	left: -56px;		
	}
	#p4{
		height: 191px;
		width: 87px;
		background: url('/images/a_stresscomm/robot4.png');
		background-size: contain;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 111px;
    	left: 3px;		
	}
	#p5{
		height: 90px;
		width: 91px;
		background: url('/images/a_stresscomm/robot5.png');
		background-size: contain;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 114px;
    	left: -159px;		
	}
	#p6{
		height: 252px;
		width: 184px;
		background: url('/images/a_stresscomm/robot6.png');
		background-size: contain;
    	background-repeat: no-repeat;
		position: relative;
    	top: 71px;
    	left: 30px;		
	}
	#p7{
		height: 156px;
		width: 60px;
		background: url('/images/a_stresscomm/ship1.png');
		background-size: contain;
    	background-repeat: no-repeat;
    	position: relative;		
    	top: -14px; 
    	left: 312px;		
	}
	#p8{
		height: 238px;
		width: 76px;
		background: url('/images/a_stresscomm/ship2.png');
		background-size: contain;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 193px;
    	left: 326px;			
	}
	#p9{
		height: 237px;
		width: 76px;
		background: url('/images/a_stresscomm/ship3.png');
		background-size: contain;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 188px;
    	left: 87px;		
	}
	#p10 {
		height: 130px;
		width: 123px;
		background: url('/images/a_stresscomm/ship4.png');
		background-size: contain;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 150px;
    	left: 71px;			
	}
	#p11 {
		height: 154px;
		width: 164px;
		background: url('/images/a_stresscomm/ship5.png');
		background-size: cover;
    	background-repeat: no-repeat;		
		position: relative;
    	top: 77px;
    	left: -74.5px;		
	}
	#p12{
		height: 110px;
		width: 117px;
		background: url('/images/a_stresscomm/ship6.png');
		background-size: contain;
    	background-repeat: no-repeat;
		position: relative;
    	top: 240px;
    	left: -217px;			
	}
}
	
	

