.display-text{
	font-size: 26px;
	font-family: "Audiowide";
	.cadet{
		fill: $light-blue;
	}
	.captain{
		fill: $pink;
	}
}

.display-fill{
	.captain{
		fill: $maroon;
		opacity: 0.35;
	}
	.cadet{
		fill: $blue;
		opacity: 0.35;
	}
	.on{
		opacity: 0.45;
	}
}

.display-line{
	fill: none;
	stroke-width: 1;
	stroke-miterlimit: 10;
	.captain{
		stroke: $pink;
	}
	.cadet{
		stroke: $light-blue;
	}
	.on{
		stroke-width: 2;
	}
}

#logo-en,
#logo-es {
	.green-fill{
		fill: $lime-green;
	}
	.white-fill{
		fill: #FFF;
	}
	.green-stroke{
		fill: none;
		stroke: $lime-green;
		stroke-miterlimit: 10;
		stroke-width: 3;
	}
	.round-stroke{
		stroke-width: 2.5;
		stroke-linecap: round;
	}
}

.svg-clipped{clip-path:url(#grid-clip);}
.grid-line{
	fill:none;
	stroke:$maroon;
	stroke-miterlimit:10;
}

/* modex svg */
.modex-svg{
	.number{fill:#99CCFF; opacity:0.25}
	.status-1{fill:#FFF; opacity:1}
	.status-2{opacity:1}
	.st1{fill:#99CCFF;}
	.st2{opacity:0.5;}
	.st3{fill:#FF99FF;}
	.stage1-btn{
		cursor:	pointer;
		.st0{fill:#660033;}
	}
	.stage2-btn{
		cursor:	pointer;
		.st0{fill:#520A3D;}
	}
	.stage3-btn{
		cursor:	pointer;
		.st0{fill:#3D1447;}
	} 
	.stage4-btn{
		cursor:	pointer;
		.st0{fill:#291F52;}
	}
	.stage5-btn{
		cursor:	pointer;
		.st0{fill:#14295C;}
	}
	.stage6-btn{
		cursor:	pointer;
		.st0{fill:#003366;}
	}
	#target{
		.cls-1, .cls-3 {
	        opacity: 0.35;
	      }
	      .cls-2 {
	        fill: #fff;
	        opacity: 0.5;
	      }
	      .cls-3 {
	        fill: $lime-green;
	      }
	      .cls-4 {
	        opacity: 0.55;
	      }
	      .cls-5 {
	        fill: none;
	        stroke: $lime-green;
	        stroke-miterlimit: 10;
	        stroke-width: 2px;
	      }
	}
}

html[lang="en"] {
	svg .es {
		display: none
	}
}

html[lang="es"] {
	svg .en {
		display: none
	}
}
